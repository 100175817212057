<template>
  <!-- Product Image -->
  <div class="mb-3">
    <div class="p-2 uploaded-media">
      <i v-bind:class="[getIconData, 'fa-5x file-icon']"></i>
      <div class="row align-items-center mb-2">
        <div class="col">
          <span class="d-block">{{ computedData.fileName }}</span>
          <span class="text-secondary">{{ computedData.fileSize }}</span>
        </div>
        <div class="col-auto">
          <div class="d-flex font-14">
            <button
              v-if="computedData.id"
              class="btn btn-outline-info"
              v-tooltip.bottom="'Bağlantıyı Kopyala'"
              v-on:click="onCopyLink"
            >
              <i v-bind:class="[copied ? 'fas fa-check' : 'far fa-clone']"></i>
            </button>
            <button v-on:click="removeFile" class="btn btn-outline-danger ml-2">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div v-if="metaVisible" class="col p-0">
        <input
          type="text"
          class="font-14 form-control"
          placeholder="Dosya Adı"
          v-model="computedData.metaTitle"
        />
      </div>
      <div v-if="metaVisible" class="col-12 mt-2 p-0">
        <input
          type="text"
          class="font-14 form-control"
          placeholder="Dosya Açıklaması"
          v-model="computedData.metaAlt"
        />
      </div>
    </div>
  </div>
  <!-- ./Product Image -->
</template>

<script>
export default {
  name: "Item",
  props: {
    data: null,
    metaVisible: {
      default: "true",
    },
  },
  model: {
    prop: "data",
  },
  data() {
    return {
      copied: false,
      extensionIcons: {
        excel: {
          icon: "fas fa-file-excel",
          color: "text-success",
        },
        pdf: {
          icon: "fas fa-file-pdf",
          color: "text-danger",
        },
        word: {
          icon: "fas fa-file-word",
          color: "text-primary",
        },
        image: {
          icon: "fas fa-image",
          color: "text-secondary",
        },
      },
    };
  },
  computed: {
    computedData() {
      return this.data;
    },
    getIconData() {
      let mimeType = "image";

      const ext = this.computedData.mimeType;

      switch (true) {
        case ext.includes("pdf"):
          mimeType = "pdf";
          break;

        case ext.includes(".doc"):
          mimeType = "word";
          break;

        case ext.includes("excel"):
        case ext.includes("xls"):
        case ext.includes("xlsx"):
        case ext.includes("sheet"):
          mimeType = "excel";
          break;
      }

      const extensionIcon = this.extensionIcons[mimeType];

      return `${extensionIcon.icon} ${extensionIcon.color}`;
    },
  },
  methods: {
    removeFile() {
      this.$parent.removeFile(this.computedData.fileName);
    },
    onCopyLink() {
      this.copied = true;
      navigator.clipboard.writeText(this.computedData.fileName);

      setTimeout(() => {
        this.copied = false;
      }, 1000);
    },
  },
};
</script>
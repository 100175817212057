<template>
  <!-- Product Images -->
  <div class="card h-100">
    <div class="card-body">
      <!-- Product Images Header -->
      <h5 class="custom-card-title">
        <i v-bind:class="icon"></i>
        <span>{{ title }}</span>
      </h5>
      <!-- Product Images Header -->
      <hr class="mb-4" />
      <div class="custom-scrollbar uploaded-image-body">
        <Item
          v-for="(file, index) in computedData.files"
          v-bind:key="index"
          v-model="computedData.files[index]"
          v-bind:metaVisible="metaVisible"
        />
      </div>
      <!-- Add Product Image -->
      <input
        type="file"
        multiple
        ref="fileInput"
        v-on:change="selectFiles"
        style="display: none"
      />
      <div class="text-danger font-14 my-2">{{ sizeLimitMessage }}</div>
      <div class="text-center">
        <span class="btn btn-outline-info" v-on:click="$refs.fileInput.click()">
          <i class="fas fa-cloud-upload-alt f-lg"></i>
          <span class="ml-2">{{ buttonText }}</span>
        </span>
      </div>
      <!-- ./Add Product Image -->
    </div>
  </div>
  <!-- ./Product Images -->
</template>

<script>
import Item from "./components/Item/Index";

export default {
  name: "Multiple",
  components: {
    Item,
  },
  props: {
    data: {
      files: [],
      filesData: null,
    },
    title: {
      default: "Dosya Yükle",
    },
    icon: {
      default: "fas fa-cloud-upload-alt",
    },
    buttonText: {
      default: "Dosya Seç",
    },
    dragText: {
      default: "Dosya Sürükle & Bırak",
    },
    orText: {
      default: "veya",
    },
    sizeLimit: {
      default: "1024",
    },
    sizeLimitMessage: {
      default: "En fazla 1024 KB boyutunda dosya yükleyebilirsiniz.",
    },
    countLimit: {
      default: "10",
    },
    metaVisible: {
      default: "true",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
    countLimitMessage() {
      return `En fazla ${this.countLimit} adet dosya yükleyebilirsiniz.`;
    },
  },
  methods: {
    selectFiles() {
      const input = this.$refs.fileInput;

      if (input.files.length) {
        if (
          this.data.files.length + input.files.length <=
          parseInt(this.countLimit)
        ) {
          for (let i = 0; i < input.files.length; i++) {
            this.createFile(input.files[i], true);
          }

          this.clearInput();
        } else {
          this.clearInput(
            "Daha fazla dosya yükleyemezsiniz!\n" + this.countLimitMessage
          );
        }
      }
    },
    clearFiles() {
      this.data.files = [];
      this.data.filesData = null;
    },
    newFilesData() {
      this.data.filesData = new FormData();
    },
    clearInput(message, reset) {
      this.$refs.fileInput.value = "";

      if (reset) {
        this.clearFiles();
      }

      if (message) {
        this.$swal({
          title: "Hata",
          text: message,
          icon: "error",
        });
      }
    },
    createFile(file, fromInput) {
      const newFile = {
        id: null,
        temp_id: "file-" + helper.getRandomInteger(1, 99999),
        metaAlt: "",
        metaTitle: "",
        fileName: "",
        fileSize: "",
        mimeType: "",
      };
      if (file.size <= parseInt(this.sizeLimit) * 1024) {
        newFile.id = file?.id || null;
        newFile.fileName = file.name;
        newFile.fileSize = helper.formatSize(file.size);
        newFile.mimeType = file.type;
        newFile.file = file;
        helper.arrayPush(this.data.files, "fileName", newFile);

        // this.data.filesData.append(file.fileName, file);
      } else {
        if (fromInput) {
          this.clearInput(
            "Yüklemeye çalıştığınız dosya boyutu yüksek!\n" +
              this.sizeLimitMessage
          );
        } else {
          this.clearInput();
        }
      }
    },
    removeFile(temp_id) {
      helper.arrayRemove(this.data.files, "fileName", temp_id);
      // this.data.filesData.delete(temp_id);

      if (!this.data.files.length) {
        this.clearFiles("", true);
      }

      this.createScratch();
      this.clearInput();
    },
    createScratch() {
      for (let i = 0; i < this.data.files.length; i++) {
        this.createFile(this.data.files[i]);
      }
    },
  },
  mounted() {
    this.createScratch();
  },
};
</script>
